import React from 'react'
import { Alert } from 'antd';
import { LoginForm } from './components/LoginForm'
import { HOME } from '../../constants/routes'

const Login = ({ loginUser, validation, loading, error, history }) => {
  const handleSubmit  = ({ validateFieldsAndScroll }) => {
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return
      }
      try {
        loginUser(values).then(() => {
          window.location.href = HOME
        })
        
      } catch(error) {
        console.log("error")
      }
    })  
  }

  return (
    <div className="login-form">
      <div className="login-form__logo">
        {/* <img alt="logo" src={config.logoPath} /> */}
        <span>BACK OFFICE</span>
      </div>
      <div className="login-form__error">
        { error && 
          <Alert
            message={error.message}
            type="error"
            showIcon
          />
        }
      </div>
      <LoginForm
        handleSubmit={handleSubmit}
        validation={validation}
        loading={loading}
      />
      
    </div>
  )
}

export default Login