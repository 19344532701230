import * as actionTypes from './Trade.ActionType'
import request from '../../../utils/request';

export const createTrade = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_TRADE.REQUEST
    })

    try {
      const result = await request({
        url: `trade`,
        method: "POST",
        data
      })

      dispatch({
        type: actionTypes.CREATE_TRADE.SUCCESS,
        data: result
      })
    } catch(error) {
      dispatch({
        type: actionTypes.CREATE_TRADE.FAILURE,
        error
      })
    }
  }
}

export const getTrades = (params) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRADES.REQUEST
    })

    try {
      const result = await request({
        url: `trade`,
        method: "GET",
        params
      })

      dispatch({
        type: actionTypes.GET_TRADES.SUCCESS,
        data: result
      })
    } catch(error) {
      dispatch({
        type: actionTypes.GET_TRADES.FAILURE,
        error
      })
    }
  }
}

export const getTrade = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRADE.REQUEST
    })

    try {
      const result = await request({
        url: `trade/${id}`,
        method: "GET"
      })

      dispatch({
        type: actionTypes.GET_TRADE.SUCCESS,
        data: result 
      })
    } catch(error) {
      dispatch({
        type: actionTypes.GET_TRADE.FAILURE,
        error
      })
    }
  }
}

export const updateTrade = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TRADE.REQUEST
    })

    try {
      const result = await request({
        url: `trade/${id}`,
        method: "PUT",
        data
      })

      dispatch({
        type: actionTypes.UPDATE_TRADE.SUCCESS,
        data: result
      })
    } catch(error) {
      dispatch({
        type: actionTypes.UPDATE_TRADE.FAILURE,
        error
      })
    }
  }
}

export const deleteTrade = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_TRADE.REQUEST
    })

    try {
      await request({
        url: `trade/${id}`,
        method: "DELETE"
      })

      dispatch({
        type: actionTypes.DELETE_TRADE.SUCCESS
      })
    } catch(error) {
      dispatch({
        type: actionTypes.DELETE_TRADE.FAILURE,
        error
      })
    }
  }
}

export const searchTrade = (text) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_TRADE.REQUEST
    })

    try {
      const result = await request({
        url: `trade/search`,
        method: "POST",
        data: { text }
      })

      dispatch({
        type: actionTypes.SEARCH_TRADE.SUCCESS,
        data: result
      })
    } catch(error) {
      dispatch({
        type: actionTypes.SEARCH_TRADE.FAILURE,
        error
      })
    }
  }
}