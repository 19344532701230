import * as actionsType from './User.ActionType'

const initState = {
  data: null,
  user: null,
  loading: false,
  error: null,
}

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actionsType.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actionsType.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case actionsType.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case actionsType.SEARCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case actionsType.SEARCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }

    case actionsType.SEARCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case actionsType.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case actionsType.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case actionsType.UPDATE_USER_PROFILE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        }

    case actionsType.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case actionsType.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case actionsType.REGISTER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        }
    default:
      return state
  }
}