import React from "react";
import ReactQuill from "react-quill";
import "./Editor.less";

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: this.props.value };
  }

  handleChange = html => {
    this.setState({ editorHtml: html });
    this.props.onChange(html);
  };

  modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      [{ align: [] }, 'direction' ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [{ color: [] }, { background: [] }],
      [{ script: 'super' }, { script: 'sub' }],
      ['blockquote', 'code-block' ],
      [{ list: 'ordered' }, { list: 'bullet'}, { indent: '-1' }, { indent: '+1' }],
      [ 'link', 'image', 'video' ],
      [ 'clean' ]
    ],
  }

  render() {
    // console.log(this.props.value, "this.props.value");
    // console.log(this.props)
    return (
      <div className="Editor">
        <ReactQuill
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={this.modules}
          defaultValue={this.props.defaultValue === undefined ? "" : this.props.defaultValue}
        />
      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: {
    // container: "#toolbar",
    // handlers: {
    //   "insertStar": insertStar,
    // }
  }
};

export default Editor;
